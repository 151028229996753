import Index from './views/Index'
import Login from './views/Login'
import Register from './views/Register'
import Order from './views/Order'
import Trade from './views/Trade'
import Team from './views/Team'
import User from './views/User'
import About from './views/About'
import Help from './views/Help'
import Area from './views/Area'
import UserInfo from './views/UserInfo'
import Withdraw from './views/Withdraw'
import Invite from './views/Invite'
import UserPay from './views/Pay/UserPay'
import NewPay from './views/Pay/NewPay'
import ErcPay from './views/Pay/ErcPay'
import BtcPay from './views/Pay/BtcPay'
import Funds from './views/Record/Funds'
import Recharge from './views/Record/Recharge'
import Withdraws from './views/Record/Withdraws'
import { HashRouter, Routes, Route, Router, useNavigate, useLocation } from 'react-router-dom';
import './lang/i18n';
import { React, useEffect } from "react";

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path='/' element={<Index></Index>}></Route>
                <Route path='/Login' element={<Login></Login>}></Route>
                <Route path='/Register' element={<Register></Register>}></Route>
                <Route path='/Order' element={<Order></Order>}></Route>
                <Route path='/Trade' element={<Trade></Trade>}></Route>
                <Route path='/Team' element={<Team></Team>}></Route>
                <Route path='/User' element={<User></User>}></Route>
                <Route path='/UserInfo' element={<UserInfo></UserInfo>}></Route>
                <Route path='/Withdraw' element={<Withdraw></Withdraw>}></Route>
                <Route path='/UserPay' element={<UserPay></UserPay>}></Route>
                <Route path='/NewPay' element={<NewPay></NewPay>}></Route>
                <Route path='/Invite' element={<Invite></Invite>}></Route>
                <Route path='/ErcPay' element={<ErcPay></ErcPay>}></Route>
                <Route path='/BtcPay' element={<BtcPay></BtcPay>}></Route>
                <Route path='/Funds' element={<Funds></Funds>}></Route>
                <Route path='/Recharge' element={<Recharge></Recharge>}></Route>
                <Route path='/Withdraws' element={<Withdraws></Withdraws>}></Route>
                <Route path='/About' element={<About></About>}></Route>
                <Route path='/Help' element={<Help></Help>}></Route>
                <Route path='/Area' element={<Area></Area>}></Route>
            </Routes>
        </HashRouter>
    );
}

export default App;

